import { Box, Button, Typography, useTheme } from "@mui/material";
import { useGetSearchIngredientListQuery } from "app/services/auth/authService";
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import React, { useState } from "react";
import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import IngredientListWidget from "./IngredientListWidget";
import { ReactComponent as NoIngredientsIcon } from "../../../assets/no_ingredients.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { addNewHistoryToLocal } from "features/search/searchSlice";
import LoadingWidget from "scenes/widgets/LoadingWidget";

const SearchIngredientsListView = ({ searchValue, ingredientCount }) => {
  const { palette } = useTheme();
  const [page, setPage] = useState(0);
  const [showPageNavigator, setShowPageNavigator] = useState(false);
  const dispatch = useDispatch();
  const pageSize = 10;
  const { data, isLoading, isFetching, isUninitialized, error } =
    useGetSearchIngredientListQuery(
      { searchValue, page, pageSize },
      {
        skip: searchValue === "" || ingredientCount <= 0,
      }
    );

  const totalPages = Math.ceil(ingredientCount / pageSize);
  if (isLoading) {
    return (
      <Box>
        <LoadingWidget />
      </Box>
    );
  }
  const handleClickAddHis = (value) => {
    dispatch(addNewHistoryToLocal(value));
  };

  if (error) {
    return (
      <Box>
        <h1>({error.status}) Could not load data</h1>
        <p className="error">{error.data ? error.data.title : ""}</p>
      </Box>
    );
  }

  if (ingredientCount === 0 && !data) {
    // return <div>No Ingredients found!</div>
    return <EmptyView palette={palette} searchValue={searchValue} />;
  }

  return (
    totalPages && (
      <Box>
        <FlexBetweenVertical gap="1rem">
          {data.map((res, index) => {
            return (
              <IngredientListWidget
                ingredientItem={res}
                handlecClick={handleClickAddHis}
              />
            );
          })}
        </FlexBetweenVertical>
        <FlexBetween>
          <Button
            onClick={() => setPage((prev) => prev - 1)}
            // isLoading={isFetching}
            disabled={page === 1}
          >
            {/* <Icon as={MdArrowBack} /> */}
          </Button>
          <Box>{`${page} / ${totalPages}`}</Box>
          <Button
            onClick={() => setPage((prev) => prev + 1)}
            // isLoading={isFetching}
            disabled={page === totalPages}
          >
            {/* <Icon as={MdArrowForward} /> */}
          </Button>
        </FlexBetween>
      </Box>
    )
  );
};

function EmptyView({ palette, searchValue }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <FlexBetweenVertical
      height="100vh"
      gap="1.5rem"
      mt="-1.875rem"
      ml="5rem"
      mr="5rem"
      sx={{ justifyContent: "center" }}
    >
      <NoIngredientsIcon />
      <Typography
        align="center"
        variant="h20"
        color={palette.textColor.mainHeading}
        fontWeight="600"
      >
        {t("searchDetail.noingredient_title")} “{searchValue}”
      </Typography>
      <FlexBetweenVertical
        gap="1.5rem"
        sx={{ justifyContent: "center", alignItems: "flex-start" }}
      >
        <Typography
          variant="h14"
          color={palette.textColor.greyLight}
          fontWeight="400"
        >
          {t("searchDetail.noingredient_subtitle")}
        </Typography>
      </FlexBetweenVertical>

      <Button
        variant="contained"
        onClick={() => navigate(-1)} //Go one step back as previous page was search page
        sx={{
          backgroundColor: palette.background.orangeDark,
          height: "2.5rem",
          mt: 1.25,
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
          justifyContent: "center",
          textTransform: "none",
          borderRadius: "1rem",
          "&:hover": {
            backgroundColor: palette.background.orangeDark,
            boxShadow: "none",
          },
        }}
      >
        Back to search
      </Button>
    </FlexBetweenVertical>
  );
}

export default SearchIngredientsListView;
