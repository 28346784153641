import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
import { useDispatch } from "react-redux";
import {
  setProfileReference,
  setUserIPLocation,
} from "features/auth/authSlice";
import { v4 as uuidv4 } from "uuid";
import BitewiseHomePage from "scenes/bitewise/homePage";
import BitewiseSearchPage from "scenes/bitewise/searchPage";
import BitewiseSearchDetailsPage from "scenes/bitewise/searchDetailsPage";
import BitewiseProductPage from "scenes/bitewise/productPage";
import HealthConditionSettingPage from "scenes/bitewise/healthsettings";
import ScanPage from "scenes/bitewise/scanPage";
import IngredientDetailsPage from "scenes/bitewise/ingredientPage";
import ProfilePage from "scenes/bitewise/profilePage";
import HistoryPage from "scenes/bitewise/historyPage";
import HealthImpactsDetailsPage from "scenes/bitewise/healthImpactsDetailsPage";
import ScrollToTop from "utils/scrollToTop";
import DisclaimerWidget from "scenes/widgets/DisclaimerWidget";
import NutrientDetailsPage from "scenes/bitewise/nutrientPage";
import ScanditScanPage from "scenes/bitewise/scanPage";

import TagManager from "react-gtm-module";
import { GTM_TRACKING_ID } from "utils/constants";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import ErrorBoundary from "ErrorBoundary";
import BlogPage from "scenes/bitewise/blogPage";
import DiscoveryPage from "scenes/bitewise/discoveryPage";
import Apiip from "apiip.net";
import { useCreateProfileReferenceMutation } from "app/services/auth/authService";
import WiseGPTPage from "scenes/bitewise/wiseGPT";
// import UserScanPage from "scenes/bitewise/userScan";
import DeveloperModePage from "scenes/bitewise/developerModePage";
import { debugLog } from "utils/commonutils";
import { useTranslation } from "react-i18next";
import SameBrandProductsPage from "scenes/bitewise/sameBrandProducts";

const tagManagerArgs = {
  gtmId: GTM_TRACKING_ID,
};
TagManager.initialize(tagManagerArgs);
// Test key e969308c-3cfa-4d6f-b6fd-58f8cf1890e9
// Production key
const apiip = Apiip("095e0f70-e882-432b-8674-767dc5c5faec");

function App() {
  const isFirstRun = useRef(true);
  const { userInfo, mode, userProfileReference, userIPLocation } = useSelector(
    (state) => state.auth
  );
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(userProfileReference);
  // const isAuth = true;
  const dispatch = useDispatch();
  // console.debug("UserProfileReference: " + userProfileReference);

  const [createProfileReference] = useCreateProfileReferenceMutation();
  const [userIPLocationFetchedState, setUserIPLocationFetchedState] =
    useState("pending");

  const createUserProfile = async () => {
    var userUUID = uuidv4();
    debugLog(`UserProfileNewUUID:` + userUUID);
    createProfileReference({
      profile_reference: userUUID,
      user_id: null,
      name: null,
      country: null,
      health_conditions: [],
    })
      .unwrap()
      .then(() => {
        dispatch(
          setProfileReference({
            userProfileReference: userUUID,
          })
        );
      })
      .then((error) => {});
  };

  const fetchAndStoreUserIpLocation = async () => {
    if (
      userIPLocation &&
      userIPLocation.lastStoreTime &&
      userIPLocation.ipapi
    ) {
      const twentyFourHoursInMillis = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      const elapsedTime = Date.now() - userIPLocation.lastStoreTime;
      if (elapsedTime <= twentyFourHoursInMillis) {
        debugLog(
          "Less than 24 hours have elapsed since the event, don't call ipapi"
        );
        setUserIPLocationFetchedState("success");
        return;
      }
    }

    apiip
      .getLocation()
      .then((results) => {
        var userIPLoc = results;
        debugLog("Fetched data from ipapi", userIPLoc);
        if (userIPLoc) {
          dispatch(
            setUserIPLocation({
              userIPLocation: { lastStoreTime: Date.now(), ipapi: userIPLoc },
            })
          );
        }
        setUserIPLocationFetchedState("success");
      })
      .catch((error) => {
        debugLog("Exception from ipapi, storing India as default location");
        // Store INDIA if user location is not fetched
        if (!userIPLocation) {
          setUserIPLocation({
            userIPLocation: {
              ipapi: { city: "New Delhi", countryName: "India" },
            },
          });
        }
        setUserIPLocationFetchedState("error");
      });
  };

  useEffect(() => {
    if (isFirstRun.current) {
      window.getCurrentURL = () => {
        return window.location.href;
      };

      if (!isAuth) {
        createUserProfile();
      }
      fetchAndStoreUserIpLocation();
      isFirstRun.current = false;
    }
  }, []);

  return (
    <div className="app">
      <ErrorBoundary fallback={<ErrorWidget />}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <ScrollToTop />
            <CssBaseline />
            <Suspense fallback={<LoadingWidget />}>
              <Routes>
                <Route
                  path="/"
                  element={
                    (userIPLocationFetchedState === "success" ||
                      userIPLocationFetchedState === "error") && (
                      <BitewiseHomePage />
                    )
                  }
                />
                <Route
                  path="/profile/:userId"
                  element={isAuth ? <ProfilePage /> : <Navigate to="/" />}
                />
                <Route path="/bitewise" element={<BitewiseHomePage />} />
                <Route
                  path="/health_condition_setting"
                  element={<HealthConditionSettingPage />}
                />
                <Route
                  path="/product/:productId"
                  element={
                    isAuth ? <BitewiseProductPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/search"
                  element={
                    isAuth ? <BitewiseSearchPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/search_detail/:searchValue"
                  element={
                    isAuth ? <BitewiseSearchDetailsPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/product-brand/:searchValue"
                  element={
                    isAuth ? <SameBrandProductsPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/scan"
                  element={isAuth ? <ScanditScanPage /> : <Navigate to="/" />}
                />
                <Route
                  path="/ingredient/:ingredientId"
                  element={
                    isAuth ? <IngredientDetailsPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/nutrient/:nutrientId"
                  element={
                    isAuth ? <NutrientDetailsPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/history"
                  element={isAuth ? <HistoryPage /> : <Navigate to="/" />}
                />
                <Route
                  path="/health_impact_detail"
                  element={
                    isAuth ? <HealthImpactsDetailsPage /> : <Navigate to="/" />
                  }
                />
                <Route
                  path="/developer_hidden_page_bw"
                  element={isAuth ? <DeveloperModePage /> : <Navigate to="/" />}
                />
                <Route path="/disclaimer" element={<DisclaimerWidget />} />
                {userIPLocation &&
                  userIPLocation.ipapi &&
                  userIPLocation.ipapi.countryName.toUpperCase() !==
                    "VIETNAM" && (
                    <Route
                      path="/biteboard"
                      element={isAuth ? <DiscoveryPage /> : <Navigate to="/" />}
                    />
                  )}
                <Route path="/wisegpt" element={<WiseGPTPage />} />
                {/* <Route path="/user-scan" element={<UserScanPage />} /> */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </ThemeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
}

export default App;
