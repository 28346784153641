import { Box, Typography, useTheme, Button } from "@mui/material"
import React, { useState, useEffect } from "react"

import FlexBetween from "components/FlexBetween"
import FlexBetweenVertical from "components/FlexBetweenVertical"
import HeaderBottomSheet from "./HeaderBottomSheet"
import { ReactComponent as FilterIcon } from "../../assets/slider_icon.svg"
import { useDispatch } from "react-redux"
import { changeHealthCondition } from "features/bottomSheet/bottomSheetSlice"
import { ReactComponent as NoProductScan } from "../../assets/no_product_scan.svg"
import { useNavigate } from "react-router-dom"
import WidgetWrapper from "components/WidgetWrapper"
import myImage from "../../assets/star.png"
import { useTranslation } from "react-i18next"

const EmptyScanResultsNewWidget = (props) => {
	const { palette } = useTheme()
	const navigate = useNavigate()

	const { t } = useTranslation()

	const backgrountWhite = palette.background.alt
	const bgOrage = palette.background.orangeDark
	const styleButton = {
		backgroundColor: bgOrage,
		width: "327px",
		height: "48px",
		padding: "8px 16px",
		color: backgrountWhite,
		textAlign: "center",
		borderRadius: "1rem",
		fontWeight: 700,
		lineHeight: "1.25rem",
		letterSpacing: "0.1px",
	}

	const iconHealth = <FilterIcon />
	const { onClose } = props

  function gotoBiteboardPage() {
    navigate('/biteboard', { state: {id: -1}});
  }

	return (
    <WidgetWrapper
      width="100%"
      sx={{
        borderRadius: "1rem",
        background: backgrountWhite,
        padding: "0",
      }}
    >
      <HeaderBottomSheet name={""} close={onClose} isDivider={false} />

      <FlexBetweenVertical
        gap="1rem"
        mt="-1.875rem"
        padding="0rem 2rem 1rem 2rem"
        sx={{ justifyContent: "center" }}
      >
        {/* <NoProductScan /> */}
        <Typography variant="h64" fontWeight="900">
          {"⭐️"}
        </Typography>
        <FlexBetweenVertical >
          <Typography
            align="center"
            variant="h20"
            color={palette.textColor.mainHeading}
            fontWeight="600"
          >
            {t("widget_scan.dont_know_what_to_scan")}
          </Typography>
          <Typography
            align="center"
            variant="h20"
            color={palette.background.orangeDark}
            fontWeight="600"
          >
            <font color={palette.textColor.mainHeading}>
              {t("widget_scan.explore")}
            </font>
            {" "}
            <font color={palette.background.orangeDark}>
                {t("widget_scan.biteboard")}
            </font>
            {" "}
            <font color={palette.textColor.mainHeading}>
              {t("widget_scan.after_biteboard")}
            </font>
          </Typography>
        </FlexBetweenVertical>
        <FlexBetweenVertical
          gap="1.5rem"
          sx={{ justifyContent: "center", alignItems: "flex-start" }}
        >
          <Typography
            variant="h14"
            color={palette.textColor.greyLight}
            fontWeight="400"
          >
            {t("widget_scan.we_have_listed_on_Bitewise_to_explore")}
          </Typography>
        </FlexBetweenVertical>
        <Button
          variant="contained"
          onClick={() => gotoBiteboardPage()}
          sx={{
            backgroundColor: palette.background.orangeDark,
            height: "2.5rem",
            mt: 1.25,
            fontSize: "14px",
            fontWeight: "bold",
            color: "white",
            justifyContent: "center",
            textTransform: "none",
            borderRadius: "1rem",
            "&:hover": {
              backgroundColor: palette.background.orangeDark,
              boxShadow: "none",
            },
          }}
        >
          {t("widget_scan.check_it_out")}
        </Button>
      </FlexBetweenVertical>
    </WidgetWrapper>
  );
}

export default EmptyScanResultsNewWidget
