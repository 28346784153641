import { Box, Typography, useTheme, Button } from "@mui/material";
import React, { useState, useEffect } from "react";

import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import HeaderBottomSheet from "./HeaderBottomSheet";
import { ReactComponent as FilterIcon } from "../../assets/slider_icon.svg";
import { useDispatch } from "react-redux";
import { changeHealthCondition } from "features/bottomSheet/bottomSheetSlice";
import { ReactComponent as NoProductScan } from "../../assets/no_product_scan.svg";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "components/WidgetWrapper";
import { useTranslation } from "react-i18next";

const EmptyScanResultsWidget = (props) => {
  const { palette } = useTheme();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const backgrountWhite = palette.background.alt;
  const bgOrage = palette.background.orangeDark;
  const styleButton = {
    backgroundColor: bgOrage,
    width: "327px",
    height: "48px",
    padding: "8px 16px",
    color: backgrountWhite,
    textAlign: "center",
    borderRadius: "1rem",
    fontWeight: 700,
    lineHeight: "1.25rem",
    letterSpacing: "0.1px",
  };

  const iconHealth = <FilterIcon />;
  const { onClose } = props;

  return (
    <WidgetWrapper
      width="100%"
      sx={{
        borderRadius: "1rem",
        background: backgrountWhite,
        padding: "0px",
      }}
    >
      <HeaderBottomSheet
        name={""}
        close={onClose}
        isDivider={false}
        paddingTop="0px"
      />

      <FlexBetweenVertical
        gap="1rem"
        mt="-1.875rem"
        sx={{ justifyContent: "center" }}
      >
        <NoProductScan />
        <Typography
          align="center"
          variant="h20"
          color={palette.textColor.mainHeading}
          fontWeight="600"
        >
          {t("scanPage.barcode_not_found_header")}
        </Typography>
        {/* <FlexBetweenVertical
          gap="1.5rem"
          sx={{ justifyContent: "center", alignItems: "flex-start" }}
        >
          <Typography
            variant="h14"
            color={palette.textColor.greyLight}
            fontWeight="400"
          >
            {t("scanPage.barcode_not_found_subtitle_1")}
          </Typography>
          <Typography
            variant="h14"
            color={palette.textColor.greyLight}
            fontWeight="400"
          >
            {t("scanPage.barcode_not_found_subtitle_2")}
          </Typography>
        </FlexBetweenVertical> */}
        <Button
          variant="contained"
          onClick={() => navigate(`/search`)}
          sx={{
            backgroundColor: palette.background.orangeDark,
            height: "2.5rem",
            mt: 1.25,
            fontSize: "14px",
            fontWeight: "bold",
            color: "white",
            justifyContent: "center",
            textTransform: "none",
            borderRadius: "1rem",
            "&:hover": {
              backgroundColor: palette.background.orangeDark,
              boxShadow: "none",
            },
          }}
        >
          Search product
        </Button>
      </FlexBetweenVertical>
    </WidgetWrapper>
  );
};

export default EmptyScanResultsWidget;
