import { Box } from "@mui/material";
import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import { useMediaQuery, Typography, useTheme } from "@mui/material";
import { useGetHistoryProductListQuery } from "../../../app/services/auth/authService";
import FlexBetween from "components/FlexBetween";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import { ReactComponent as NoProductsIcon } from "../../../assets/no_products.svg";
import { useEffect, useState } from "react";
import WidgetWrapper from "components/WidgetWrapper";
import GenericNavBar from "../navbar/GenericNavBar";
import SearchScanWidget from "scenes/widgets/SearchScanWidget";
import { Helmet } from "react-helmet";
import { Virtuoso } from "react-virtuoso";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import NavBarDesktop from "../navbar/NavBarDesktop";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setIsRefetchHistory } from "features/bottomSheet/bottomSheetSlice";

const HistoryPage = () => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { isRefetchHistory } = useSelector((state) => state.bottomSheet);
  const textMainHeading = palette.textColor.mainHeading;
  const textGreySecondary = palette.textColor.greySecondary;
  const backgroundProfilePageHead = palette.background.profilePageHead;
  const grayColor = palette.textColor.greySecondary;
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const navBar = isNonMobileScreens ? (
    <NavBarDesktop />
  ) : (
    <GenericNavBar title={`${t("menu.history")}`} />
  );
  const heightGenericNavBar = isNonMobileScreens ? "0px" : "72px";
  const totalOtherContentHeight = "150px";
  const heightBox = `calc(100vh - ${heightGenericNavBar} - ${totalOtherContentHeight})`;
  const [loadingMore, setLoadingMore] = useState(false);
  const [dataShow, setDataShow] = useState([]);
  const { isChangeHealth } = useSelector((state) => state.bottomSheet);

  const {
    data: historyList,
    isLoading: historyIsLoading,
    error: historyError,
    refetch: historyRefetch,
  } = useGetHistoryProductListQuery({ page, pageSize });

  const loadMoreItems = () => {
    if (!loadingMore && pageSize * (page + 1) && historyList?.length > 0) {
      setLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (isRefetchHistory) {
      window.location.reload();
      dispatch(setIsRefetchHistory(false));
    }
  }, [isRefetchHistory]);

  const handleItemContent = (index) => {
    return (
      <ProductListItemWidget
        key={index}
        productItem={dataShow[index]}
        showBoxShadow={true}
        showDivider={false}
        historyPage={true}
        alternative={dataShow[index]?.alternative}
        value={dataShow[index].product_id}
      />
    );
  };

  useEffect(() => {
    if (historyList?.length > 0 && dataShow?.length > 0) {
      setDataShow((preData) => [...preData, ...historyList]);
    } else if (historyList?.length > 0) {
      setDataShow(historyList);
    }
    setLoadingMore(false);
  }, [historyList]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      section_of_website: "history",
    });
  }, []);

  const renderHistoryView = () => {
    if (dataShow && dataShow?.length > 0) {
      return (
        <Box sx={{ height: heightBox, width: "100%" }}>
          <Virtuoso
            style={{
              height: heightBox,
              width: "100%",
              borderRadius: "1rem",
            }}
            data={dataShow}
            itemContent={handleItemContent}
            endReached={loadMoreItems}
          />
        </Box>
      );
    }
    return <EmptyHistoryView palette={palette} />;
  };

  return (
    <Box
      height={"100vh"}
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Helmet title={`${t("menu.history")} | Bitewise`} />
      {navBar}
      <Box
        width="100%"
        display={isNonMobileScreens ? "flex" : "block"}
        gap="0.5rem"
        justifyContent="space-between"
        flexGrow={1}
      >
        <Box flexBasis={isNonMobileScreens ? "26%" : undefined}></Box>
        <Box
          flexBasis={isNonMobileScreens ? "30%" : undefined}
          width={isNonMobileScreens ? "30%" : undefined}
        >
          {historyIsLoading ? (
            <LoadingWidget />
          ) : historyError ? (
            <FlexBetweenVertical
              sx={{
                width: "100%",
                height: isNonMobileScreens
                  ? "100vh"
                  : `calc(100vh - ${heightGenericNavBar})`,
              }}
              alignItems={"center"}
            >
              <ErrorWidget error={historyError} />
            </FlexBetweenVertical>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              backgroundColor={backgroundProfilePageHead}
              padding="1.5rem"
              height={
                isNonMobileScreens
                  ? "100vh"
                  : `calc(100vh - ${heightGenericNavBar})`
              }
              justifyContent={historyList?.length > 0 ? "flex-start" : "center"}
            >
              <WidgetWrapper
                sx={{
                  gap: "1rem",
                  borderRadius: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "transparent",
                  padding: 0,
                }}
              >
                <Typography
                  variant="h4"
                  fontWeight="900"
                  color={textGreySecondary}
                >
                  {dataShow?.length > 0
                    ? `${dataShow?.length} ${t("history.products")}`
                    : ""}
                </Typography>
                {renderHistoryView()}
                {dataShow?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-start",
                      gap: "8px",
                      alignSelf: "stretch",
                      color: grayColor,
                    }}
                  >
                    <ErrorIcon
                      sx={{
                        width: "14px",
                        height: "14px",
                        marginTop: "4px",
                      }}
                    />
                    <Typography>
                      {t(
                        "history.product_ratings_shown_here_are_based_on_the_current_health_settings"
                      )}
                    </Typography>
                  </Box>
                )}
              </WidgetWrapper>
            </Box>
          )}
        </Box>
        {isNonMobileScreens && (
          <Box flexBasis="26%">
            <Box m="1.5rem 0" />
          </Box>
        )}
      </Box>
    </Box>
  );
};

function EmptyHistoryView({ palette }) {
  const { t } = useTranslation();
  return (
    <FlexBetweenVertical
      width="100%"
      gap="1.5rem"
      height="100%"
      flexDirection={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        borderColor: palette.borderColor.grey,
      }}
    >
      <NoProductsIcon style={{ width: "4rem", height: "4rem" }} />
      <FlexBetween
        gap={"5px"}
        flexDirection={"column"}
        width={"85%"}
        minHeight={"5rem"}
      >
        <Typography
          variant="h6"
          fontWeight="600"
          color={palette.textColor.main}
          textAlign={"center"}
          lineHeight={"1.2rem"}
        >
          {t("history.you_have_not_searched_or_scan_any_product")}
        </Typography>
        <Typography
          variant="body1"
          fontWeight="400"
          color={palette.textColor.greyLight}
          textAlign={"center"}
        >
          {t(
            "history.your_search_or_scan_history_lives_here_start_scanning_or_searching"
          )}
        </Typography>
      </FlexBetween>
      <FlexBetween>
        <SearchScanWidget
          showPlaceHolder={false}
          showNote={false}
          isHistoryEmpty={true}
        />
      </FlexBetween>
    </FlexBetweenVertical>
  );
}

export default HistoryPage;
