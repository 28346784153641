import { Box, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Transition } from "scenes/bitewise/scanPage";
import EmptyScanResultsNewWidget from "./EmptyScanResultsNewWidget";

const PopUpBiteboard = (props) => {
  const [startTime, setStartTime] = useState(null);
  const [totalTime, setTotalTime] = useState(0);

  const [showScanWidget, setShowScanWidget] = useState(false);
  const handleClickCloseBottomSheet = () => {
    if (barcodePickerRef.current) barcodePickerRef.current.resumeScanning();
  };
  const { barcodePickerRef, handleCount } = props;

  useEffect(() => {
    const handleFocus = () => {
      setStartTime(new Date());
    };

    const handleBlur = () => {
      if (startTime) {
        const endTime = new Date();
        const elapsedTime = endTime - startTime;
        setTotalTime((prevTotalTime) => prevTotalTime + elapsedTime / 1000);
        setStartTime(null);
      }
    };

    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    let intervalId;

    const handleInterval = () => {
      setTotalTime((prevTotalTime) => {
        const newTotalTime = prevTotalTime + 1;

        if (newTotalTime >= 15) {
          setShowScanWidget(true);
          clearInterval(intervalId);
        }
        return newTotalTime;
      });
    };

    intervalId = setInterval(handleInterval, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {showScanWidget && (
        <Dialog
          open={showScanWidget}
          onClose={() => {
            setShowScanWidget(false);
            handleClickCloseBottomSheet();
            handleCount();
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          TransitionComponent={Transition}
          fullWidth
          sx={{
            "& .MuiPaper-root": {
              position: "absolute",
              bottom: "0rem",
              borderRadius: "1rem",
            },
          }}
        >
          <Box>
            <EmptyScanResultsNewWidget
              onClose={() => {
                setShowScanWidget(false);
                handleCount();
              }}
            />
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default PopUpBiteboard;

