import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  useGetSearchByBrandQuery,
  useGetSearchProductListQuery,
} from "app/services/auth/authService";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import React, { useEffect, useRef, useState } from "react";
import ProductListItemWidget from "scenes/widgets/ProductListItemWidget";
import { ReactComponent as NoProductsIcon } from "../../../assets/no_products.svg";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Virtuoso } from "react-virtuoso";
import { useDispatch, useSelector } from "react-redux";
import { addNewHistoryToLocal } from "features/search/searchSlice";
import LoadingWidget from "scenes/widgets/LoadingWidget";
import ErrorWidget from "scenes/widgets/ErrorWidget";
import {
  setIsRefetchSearch,
  setWidget,
} from "features/bottomSheet/bottomSheetSlice";
import DialogWidget from "scenes/widgets/DialogWidget";
import DisclaimerWidget from "scenes/widgets/DisclaimerWidget";
import { ReactComponent as DisclaimerIcon } from "./../../../assets/disclaimer_infor.svg";

const SearchListItemPagi = ({
  searchValue,
  productCount,
  heightNoneTab = "0px",
  isBrand = false,
}) => {
  const { palette } = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const textColorGreySecondary = palette.textColor.greyLight;
  const [page, setPage] = useState(0);
  const [pageSize] = useState(10);
  const [dataShow, setDataShow] = useState();
  const searchQueryId = useRef(0);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { isRefetchSearch } = useSelector((state) => state.bottomSheet);

  const { openDisclaimer } = useSelector(
    (state) => state.bottomSheet.openBottomSheet
  );
  const [payload, setPayload] = useState({
    name: "openHealthImpact",
    isOpen: false,
  });

  const handleClickOpen = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: true,
    });
  };
  const handleClose = (openWidgetName) => {
    setPayload({
      name: openWidgetName,
      isOpen: false,
    });
  };

  useEffect(() => {
    dispatch(setWidget(payload));
  }, [dispatch, payload]);

  const {
    data: searchProducts,
    isLoading,
    isFetching,
    isUninitialized,
    error,
  } = useGetSearchProductListQuery(
    {
      searchValue: searchValue,
      page,
      pageSize,
      searchQueryId: searchQueryId.current,
    },
    {
      skip:
        searchValue === "" ||
        productCount <= 0 ||
        (page === 0 && searchQueryId.current !== 0) ||
        isBrand === true,
    }
  );
  const {
    data: productListBrand,
    isLoading: loadingProductListBrand,
    isFetching: isFetchingBrand,
    isUninitialized: isUninitializedBrand,
    error: errorBrand,
  } = useGetSearchByBrandQuery(
    {
      searchValue: searchValue,
      page,
      pageSize,
    },
    {
      skip:
        searchValue === "" ||
        productCount <= 0 ||
        (page === 0 && searchQueryId.current !== 0) ||
        isBrand === false,
    }
  );

  useEffect(() => {
    if (isRefetchSearch) {
      window.location.reload();
      dispatch(setIsRefetchSearch(false));
    }
  }, [isRefetchSearch]);

  const handleClick = (value) => {
    dispatch(addNewHistoryToLocal(value));
  };
  const handleItemContent = (index) => {
    return (
      <ProductListItemWidget
        key={index}
        productItem={dataShow[index]}
        showBoxShadow={true}
        shadowPercent={"4%"}
        showDivider={false}
        historyPage={false}
        value={dataShow[index].product_id}
        handeClick={handleClick}
        searchQueryId={searchQueryId}
      />
    );
  };

  const heightGenericNavBar = isNonMobileScreens ? "0px" : "72px";
  const heightTabAndGap = "60px";
  const totalOtherContentHeight = "80px";

  const heightContent = `calc(100vh - ${heightGenericNavBar}`;
  const heightBox = `calc(100vh - ${heightGenericNavBar} - ${totalOtherContentHeight} - ${heightTabAndGap} + ${heightNoneTab})`;
  const [loadingMore, setLoadingMore] = useState(false);

  const loadMoreItems = () => {
    if (!loadingMore && pageSize * (page + 1) < productCount) {
      setLoadingMore(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    // Is JSON array
    const data = isBrand ? productListBrand : searchProducts;
    if (Array.isArray(data)) {
      if (data?.length > 0 && dataShow?.length > 0) {
        setDataShow((preData) => [...preData, ...data]);
      } else if (data?.length > 0) {
        setDataShow(data);
      }
      setLoadingMore(false);
    } else if (data) {
      if (data?.products?.length > 0 && dataShow?.length > 0) {
        setDataShow((preData) => [...preData, ...data?.products]);
      } else if (data?.products?.length > 0) {
        setDataShow(data?.products);
      }
      if (searchQueryId.current === 0 && data?.id) {
        searchQueryId.current = data?.id;
      }
      setLoadingMore(false);
    }
  }, [productListBrand, searchProducts, isBrand]);

  // const data = [1, 2, 3]
  const totalPages = Math.ceil(productCount / pageSize);
  if (isLoading || loadingProductListBrand) {
    return (
      <Box>
        <LoadingWidget />
      </Box>
    );
  }

  if (error || errorBrand) {
    // console.log(error)
    return <ErrorWidget error={error} />;
  }

  //Don't show if query is not initiaoized
  if (
    productCount === 0 &&
    !dataShow &&
    !isLoading &&
    !loadingProductListBrand &&
    !isFetchingBrand &&
    !isFetching
  ) {
    return <EmptyView palette={palette} searchValue={searchValue} />;
  }

  return (
    totalPages && (
      <Box
        sx={{
          height: heightContent,
        }}
      >
        <FlexBetweenVertical>
          <Typography
            sx={{ marginBottom: "0.3rem", marginX: "1rem" }}
            variant="h11"
            fontWeight="400"
            color={textColorGreySecondary}
          >
            {t("productDetail.healthimpacts_subtitle")}
            <IconButton
              sx={{
                display: "inline",
                marginLeft: "0.3rem",
                fontSize: "10px",
                padding: "0",
              }}
              onClick={() => {
                handleClickOpen("openDisclaimer");
              }}
            >
              <DisclaimerIcon />
            </IconButton>
          </Typography>

          <DialogWidget
            open={openDisclaimer}
            onClose={() => handleClose("openDisclaimer")}
            dialogContent={
              <DisclaimerWidget onClose={() => handleClose("openDisclaimer")} />
            }
          />
          {dataShow && dataShow.length > 0 ? (
            <Box sx={{ height: "100%", width: "100%" }}>
              {dataShow && (
                <Virtuoso
                  style={{
                    height: heightBox,
                    width: "100%",
                    borderRadius: "1rem",
                  }}
                  data={dataShow}
                  itemContent={handleItemContent}
                  endReached={loadMoreItems}
                />
              )}
            </Box>
          ) : (
            <EmptyView palette={palette} searchValue={searchValue} />
          )}
        </FlexBetweenVertical>
      </Box>
    )
  );
};

function EmptyView({ palette, searchValue }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <FlexBetweenVertical
      height="100vh"
      gap="1.5rem"
      mt="-1.875rem"
      ml="5rem"
      mr="5rem"
      sx={{ justifyContent: "center" }}
    >
      <NoProductsIcon />
      <Typography
        align="center"
        variant="h20"
        color={palette.textColor.mainHeading}
        fontWeight="600"
      >
        {t("searchDetail.noproduct_title")} “{searchValue}”
      </Typography>
      <FlexBetweenVertical
        gap="1.5rem"
        sx={{ justifyContent: "center", alignItems: "flex-start" }}
      >
        <Typography
          variant="h14"
          color={palette.textColor.greyLight}
          fontWeight="400"
        >
          {t("searchDetail.noproduct_subtitle_1")}
        </Typography>
        <Typography
          variant="h14"
          color={palette.textColor.greyLight}
          fontWeight="400"
        >
          {t("searchDetail.noproduct_subtitle_2")}
        </Typography>
      </FlexBetweenVertical>
      <Button
        variant="contained"
        onClick={() => navigate(-1)}
        sx={{
          backgroundColor: palette.background.orangeDark,
          height: "2.5rem",
          mt: 1.25,
          fontSize: "14px",
          fontWeight: "bold",
          color: "white",
          justifyContent: "center",
          textTransform: "none",
          borderRadius: "1rem",
          "&:hover": {
            backgroundColor: palette.background.orangeDark,
            boxShadow: "none",
          },
        }}
      >
        {t("searchDetail.button_backtosearch")}
      </Button>
    </FlexBetweenVertical>
  );
}

export default SearchListItemPagi;
