import { Typography, useTheme, Button, Box } from "@mui/material";
import FlexBetweenVertical from "components/FlexBetweenVertical";
import HeaderBottomSheet from "./HeaderBottomSheet";
import { useNavigate } from "react-router-dom";
import WidgetWrapper from "components/WidgetWrapper"
import { useTranslation } from "react-i18next";
import { ReactComponent as CameraDeniedIcon } from "../../assets/camera_disabled.svg";;

const ErrorAccessWidget = (props) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  // Check if the browser supports the getUserMedia API

  function redirectToSettings() {
    // Redirect the user to the browser settings page
    // This example redirects to the generic settings page, you may need to adjust it based on the browser
    window.location.href = "chrome://settings/content/camera";
    // For other browsers, you may need different URLs
    // Firefox: 'about:preferences#privacy'
    // Safari: 'preferences:privacy'
    // Edge: 'edge://settings/content/camera'
  }

  const { t } = useTranslation();

  const backgrountWhite = palette.background.alt;
  const { onClose } = props;

  return (
    <WidgetWrapper
      width="100%"
      sx={{
        borderRadius: "1rem",
        background: backgrountWhite,
        padding: "0",
      }}
    >
      <HeaderBottomSheet name={""} close={onClose} isDivider={false} />

      <FlexBetweenVertical
        gap="1rem"
        mt="-1.875rem"
        padding="1rem"
        sx={{ justifyContent: "center" }}
      >

          <CameraDeniedIcon style={{width:"64px", height:"64px"}}/>
          <FlexBetweenVertical
            gap="1.5rem"
            pl="1rem"
            pr="1rem"
            sx={{ justifyContent: "center", alignItems: "flex-start" }}
          >
            <Typography
              fontSize="16"
              fontWeight="400"
            >
              {"Camera permission denied. Please grant access to use scan feature and reload this page."}
            </Typography>
          </FlexBetweenVertical>

        {/* <Button
          variant="contained"
          onClick={() => {
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
              // Request access to the camera
              navigator.mediaDevices
                .getUserMedia({ video: true })
                .then(function (stream) {
                  // User granted access, you can do further camera-related actions here
                  console.log("Camera access granted");
                })
                .catch(function (error) {
                  // User denied access or there was an error
                  console.error("Error accessing camera:", error);

                  // Redirect the user to the settings page
                  redirectToSettings();
                });
            } else {
              console.error("getUserMedia not supported in this browser");
            }
          }}
          sx={{
            backgroundColor: palette.background.orangeDark,
            height: "2.5rem",
            mt: 1.25,
            fontSize: "14px",
            fontWeight: "bold",
            color: "white",
            justifyContent: "center",
            textTransform: "none",
            borderRadius: "1rem",
            "&:hover": {
              backgroundColor: palette.background.orangeDark,
              boxShadow: "none",
            },
          }}
        >
          {t("User manual")}
        </Button> */}
      </FlexBetweenVertical>
    </WidgetWrapper>
  );
};

export default ErrorAccessWidget;
